<template>
<div>
    <button @click="Cancel()" class="btn btn-link redirect-home">
        <b-img width="11" height="9" :src="closeFilterIcon" /> Back to requests</button>
    <div class="page-details row custom-card">
        <div class="text-center text-danger col-lg-12" v-if="IsDataLoaded&&!IsApproveOrRejectEnabled">The Request is already approved or rejected.</div>
        <div class="col-lg-12 approve-only-section" v-if="IsDataLoaded">
            <div class="row top-section">
                <h4 class="col-lg-3 card-main-title">Request Review &amp; Approval</h4>

                <div>
                    <div class="transaction-number">
                        <p class="transaction-label">Transaction ID Number</p>
                        <p class="transaction-id">{{Request.ID}}</p>
                    </div>
                </div>
            </div>
            <div class="row top-section">
                <div class="col-lg-4">
                    <p class="review-text"><span class="review-label">Requested by:</span> {{Request.Requestor|formatEmailToUserName}}</p>
                </div>
                <div class="col-lg-4">
                    <p class="review-text"><span class="review-label">Requested on:</span> {{Request.CreatedDate|formatDate}}</p>
                </div>
            </div>
        </div>
        <wiretransfer-overview class="col-lg-9" :Request="Request" :ID="id"></wiretransfer-overview>
        <div class="col-lg-3 approval-side-section">
            <div class="row">
                <p class="approve-reject-label"> Approve / Reject Wire Transfer Request</p>
                <p class="approve-reject-content">
                    Please review the submitted request, provide the comment and approve or deny the request.
                </p>
                <b-form-group class="comments">
                    <b-textarea :rows="5" v-model="Comments" placeholder="Add Comments">
                    </b-textarea>
                </b-form-group>

                <div class="col-lg-12 text-center btn-stacked">
                    <button @click="Reject()" class="text-uppercase btn btn-secondary btn-primary">
                        Deny Request
                    </button>
                </div>
                <div class="col-lg-12 text-center btn-stacked">
                    <button @click="Approve()" class="btn custom-button btn-primary">
                        Approve Request
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
// import {
//     requiredIf
// } from "vuelidate/lib/validators";
import {mapGetters } from 'vuex';
import store from "@/store";
import authenticate from "../../mixins/wiretransferworkflowvauthorization";
const moment = require("moment");
import {
    requiredIf
    // minLength
} from "vuelidate/lib/validators";
export default {
    name: "WireTransferApproveLevel1",
    mixins: [authenticate],
    validations: {
        Comments: {
            required: requiredIf(function () {
                return this.RejectComments == true;
            })
        }
    },
    data() {
        return {
            closeFilterIcon: require("@/assets/Icons/close-filter-icon.svg"),
            Request: {},
            Error: false,
            ShowRejectModal: false,
            Comments: "",
            HideFooterButtons: true,
            RejectComments: false,
            AgencyList: [],
            ApprovalDetails: {
                NewVendor: null,
                VerballyConfirmedBankingInformation: null,
                FirstName: null,
                LastName: null,
                Title: null,
                Email: null,
                PhoneNumber: null,
                Comments: null,
                BankingInfoChanged: null
            }
        };
    },
    filters: {
        formatDate(date) {
            return moment(date).isValid() ? moment(date).format("MM/DD/YYYY") : "-";
        },
        formatEmailToUserName(email) {
            if (email.indexOf("@") > -1) {
                let name = email.substring(0, email.indexOf("@"));
                let values = name.split(".");
                name = "";
                for (var i = 0; i < values.length; i++) {
                    let val = values[i];
                    name += " " + val.substring(0, 1).toUpperCase() + val.substring(1, val.length);
                }
                return name;
            } else {
                return email;
            }
        }
    },
    props: ["id"],
    computed: {
         ...mapGetters({
            loggedInUser: "emailAddress"
        }),
        canApproveLevel1WireTransferRequest() {
            return this.$store.getters.canApproveLevel1WireTransferRequest;
        },
        userDataMapping() {
            return this.$store.getters.dataMappings;
        },
        AllAgencyAccess() {
            return this.userDataMapping != null && this.userDataMapping.cdc839a5c1db4c43bb5b0e180cf0270e == "*";
        },
        IsAgencyArray() {
            return this.userDataMapping != null && !this.AllAgencyAccess && Array.isArray(this.userDataMapping.cdc839a5c1db4c43bb5b0e180cf0270e);
        },
        RequestedCode() {
            return this.Request ? this.Request.ProductCode : "";
        },
        ModalTitle() {
            return `Reject Request ${this.ClientName}`;
        },
        canApproveCurrentWorkflowRequest() {
            return this.$store.getters.canApproveLevelCFOWireTransferRequest;
        },
        IsApproveOrRejectEnabled() {
            return true; // this.Request&&this.Request.TaskStatus==status.ApproveLevel1;
        },
        IsDataLoaded() {
            return this.Request && this.Request.PaymentType != null ? true : false;
        },
    },
    watch: {
        "Request": function () {
            if (!this.UserAgency(this.Request.Agency) ||!this.canApproveCurrentWorkflowRequest) {
                this.$router.push({
                    name: "error",
                });
            }

        }
    },
    methods: {
        UserAgency(agency) {
            return this.AllAgencyAccess || (this.IsAgencyArray ? this.userDataMapping.cdc839a5c1db4c43bb5b0e180cf0270e.findIndex(d => d == agency) > -1 : false);
        },

        SetDirty() {
            this.$v.Comments.$touch();
        },
        ResetDirty() {
            this.$v.Comments.$reset();
        },
        GetData() {
            return {
                ID: parseInt(this.id),
                Comments: this.Comments,
                ActiveTaskID: this.Request.ActiveTaskID | 0,
                ProjectID: this.Request.OmniflowProjectID | 0,
                ProcessID: this.Request.OmniflowProcessID | 0,
            };
        },
        ShowReject() {
            this.ShowRejectModal = true;
        },
        CancelReject() {
            this.ShowRejectModal = false;
        },
        Reject() {
            this.ShowRejectModal = false;
            this.RejectComments = true;
            this.$v.Comments.$touch();
            if (!this.$v.$invalid) {
                this.$store
                    .dispatch("wiretransfer/RejectRequestLevel2", this.GetData())
                    .then(() => {
                        store.dispatch(
                            "progress/setSuccessMessage",
                            `Request has been rejected`
                        );
                        this.$router.push("/WireTransfers");
                    })
                    .catch((ex) => {
                        if (ex.response) {
                            this.SetErrorMessage(
                                "Error while rejecting request",
                                ex.response.data
                            );
                        } else {
                            this.SetErrorMessage("Error while rejecting request", ex);
                        }
                    });
            }
        },
        Approve() {
            this.ShowRejectModal = false;
            this.RejectComments = false;
            this.$store
                .dispatch("wiretransfer/ApproveRequestLevel2", this.GetData())
                .then(() => {
                    store.dispatch(
                        "progress/setSuccessMessage",
                        `Wire transfer request has been approved`
                    );
                    this.$router.push("/WireTransfers");
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.SetErrorMessage(
                            "Error while approving request",
                            ex.response.data
                        );
                    } else {
                        this.SetErrorMessage("Error while approving request", ex);
                    }
                });
        },
        SetErrorMessage(message, ex) {
            store.dispatch(
                "progress/setErrorMessage",
                `${message}. The error is ${ex}`
            );
        },
        Cancel() {
            this.$router.push("/WireTransfers");
        },
    },
};
</script>

<style scoped>
.reject-section {
    padding-bottom: 10px;
}

.review-text {

    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    color: #4d5161;
}

.review-label {
    font-size: 14px;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d5161;
}

@media screen and (min-width: 1200px) {
    .page-details {
        padding: 1rem 3rem 0px !important;
    }
}

.row.page-title {
    height: 55px;
    background-color: #eef3f5 !important;
}

.row.page-title>label {
    padding: 14px 0px 14px 55px !important;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.81px;
    color: #4d5161;
    text-transform: none !important;
}

.review-component>div:first-child {
    box-shadow: 0 1px 3px 0 rgb(9 30 66 / 12%) !important;
    border: solid 1px rgba(239, 242, 244, 0.2) !important;
    border-right: none;
    border-radius: 8px 0px 0px 8px;
    background-color: #ffffff;
}

.page-details.row.custom-card {
    /* margin-left: 30px;
    margin-right: 30px; */
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(9, 30, 66, 0.12);
    border: solid 1px rgba(239, 242, 244, 0.2);
    background-color: #ffff !important;
}

.approve-only-section>.top-section:first-child {
    /* opacity: 0.2; */
    border-bottom: solid 1px rgba(151, 151, 151, 0.2);
    padding-bottom: 15px;
}

.approve-only-section>.top-section:nth-child(2) {
    /* opacity: 0.2; */
    border-bottom: solid 1px rgba(151, 151, 151, 0.2);
    padding-bottom: 0px;
}

/* .approve-only-section {
    padding-left: 30px;
    padding-right: 30px;

} */
.top-section h4,
.top-section>div:first-child {
    padding-left: 0px;
}

.row.page-title {
    background-color: transparent !important;
}

.redirect-home {
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
    margin-left: 12px !important;
}

.redirect-home img {
    vertical-align: baseline;
}

.btn-stacked {
    padding-bottom: 20px;
}

.approval-side-section {
    padding-right: 20px !important;
}

.approve-reject-label {
    padding: 20px 0px 0px 20px;
    margin-bottom: 5px !important;
}

.approve-reject-content {
    padding: 0px 0px 0px 20px;
}

.form-group.comments {
    width: 100% !important;
}

.approve-only-section>.top-section:first-child {
    padding-bottom: 32px;
}
</style>
